import Link from 'next/link';
import React, { useState } from 'react';
import { BiConversation } from 'react-icons/bi';
import { FaRegEdit } from 'react-icons/fa';

import { RekaLink } from '@/components/reka-link';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { Box, IconButton, Text } from '@chakra-ui/react';

type MobileRightPanelNavProps = {
    onOpen: () => void;
    currentTitle: string | undefined;
};
export const MobileRightPanelNav = (props: MobileRightPanelNavProps) => {
    const { onOpen, currentTitle } = props;
    const { isLoggedOutChat, showSignupGate, clearLoggedOutChat } = useLoggedOutChat();
    const [showNotice, setShowNotice] = useState(isLoggedOutChat);
    const handleClick = (e: React.MouseEvent) => {
        if (isLoggedOutChat) {
            e.preventDefault();
            if (!showNotice) {
                return clearLoggedOutChat();
            }
            setShowNotice(false);
            return showSignupGate('saves');
        }
    };
    return (
        <Box
            as="header"
            position="sticky"
            top="0"
            right="0"
            w={'100%'}
            display={['flex', 'flex', 'flex', 'flex', 'none']}
            justifyContent={'space-between'}
            alignItems={'center'}
            p={['12px', '16px']}
            zIndex={1}
            gap={['4px', '8px']}
        >
            {/* Header content here */}
            <Link onClick={handleClick} href={'/chat'}>
                <IconButton
                    color={'text-subtle'}
                    isRound
                    variant={'ghost'}
                    size={'md'}
                    fontSize={['20px', '24px']}
                    aria-label={'New chat'}
                    icon={<FaRegEdit />}
                />
            </Link>

            <Text textAlign={'center'} noOfLines={1}>
                {currentTitle || <RekaLink href={'/'} />}
            </Text>

            <IconButton
                color={'text-subtle'}
                isRound
                variant={'ghost'}
                size={'md'}
                fontSize={'24px'}
                onClick={onOpen}
                aria-label={'Menu and pasts conversations'}
                icon={<BiConversation />}
            />
        </Box>
    );
};
