import React, { useRef, useState } from 'react';

import { SidePanel } from '@/components/left-panel';
import { RekaLink } from '@/components/reka-link';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from '@chakra-ui/react';

type MenuDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
};
export function MenuDrawer(props: MenuDrawerProps) {
    const { isOpen, onClose } = props;
    const ref = useRef(null);
    const { isLoggedOutChat, showSignupGate, clearLoggedOutChat } = useLoggedOutChat();
    const [showNotice, setShowNotice] = useState(isLoggedOutChat);
    const handleClick = () => {
        if (isLoggedOutChat) {
            if (!showNotice) {
                return clearLoggedOutChat();
            }
            setShowNotice(false);
            return showSignupGate('saves');
        }
    };

    return (
        <Drawer initialFocusRef={ref} placement={'right'} onClose={onClose} isOpen={isOpen} size={'full'}>
            <DrawerOverlay />
            <DrawerContent h={'var(--chakra-vh)'} backgroundColor={'background-main'}>
                <DrawerHeader
                    borderBottomWidth="1px"
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    borderWidth={0}
                    padding={'8px 12px'}
                >
                    <RekaLink onClick={handleClick} href={'/chat'} />
                </DrawerHeader>
                <DrawerCloseButton ref={ref} />
                <DrawerBody>
                    <Box h="100%" maxW={'460px'} pb={'12px'} margin={'auto'}>
                        <SidePanel onClose={onClose} isMobile={true} />
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
