import audioBufferToWav from 'audiobuffer-to-wav';
import React, { useRef, useState } from 'react';
import { BsFillMicFill } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';
import { continuousVisualizer, currentVisualizer } from 'sound-visualizer';

import { rekaFactory } from '@/api/reka';
import cl from '@/components/components.module.css';
import { IconButton, Stack, Tooltip } from '@chakra-ui/react';

export function SpeechToSpeech() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const startRecording = async () => {
        const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });

        const userVisualizer = continuousVisualizer(audioStream, canvasRef.current!);
        userVisualizer.start();

        mediaRecorderRef.current = new MediaRecorder(audioStream);
        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
            userVisualizer.reset();
            setIsRecording(false);
            setLoading(true);
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            const audioBuffer = await blobToAudioBuffer(audioBlob);
            const wavBuffer = audioBufferToWav(audioBuffer);
            const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
            const base64AudioString = await convertBlobToBase64(wavBlob);
            const reka = await rekaFactory(null);
            const { data, err } = await reka.speech2speech({ audio: base64AudioString });
            if (err) {
                setLoading(false);
                return;
            }
            const responseBase64 = `data:audio/wav;base64,${data}`;
            const buffer = base64ToArrayBuffer(data!);
            const stream = await wavToMediaStream(buffer);
            const agentVisualizer = currentVisualizer(stream, canvasRef.current!);
            agentVisualizer.start();
            const audio = new Audio(responseBase64);
            audio.play();
            audio.onended = () => {
                setLoading(false);
                agentVisualizer.reset();
            };
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
    };
    const sendRecording = async () => {
        if (!mediaRecorderRef.current) return;
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current?.stream.getTracks().forEach((track) => track.stop());
    };
    return (
        <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
            <canvas ref={canvasRef} />
            <IconButton
                isLoading={loading}
                marginLeft={'8px'}
                className={isRecording ? cl.pulse : ''}
                icon={isRecording ? <MdSend /> : <BsFillMicFill />}
                aria-label={isRecording ? 'Send' : 'Record'}
                onClick={isRecording ? sendRecording : startRecording}
                size="lg"
                fontSize={'20px'}
                isRound
                variant={'ghost'}
            />
        </Stack>
    );
}

async function wavToMediaStream(wavData: ArrayBuffer): Promise<MediaStream> {
    const audioContext = new AudioContext();

    // Decode the WAV data to an AudioBuffer
    const audioBuffer = await audioContext.decodeAudioData(wavData);

    // Create a MediaStreamAudioDestinationNode, which gives us a MediaStream
    const destination = audioContext.createMediaStreamDestination();

    // Create an AudioBufferSourceNode from the AudioBuffer
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;

    // Connect the source to the destination (which is our MediaStream)
    source.connect(destination);
    source.start();

    // Return the MediaStream from the destination node
    return destination.stream;
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
    console.log(base64);
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64 = reader.result as string;
            console.log(base64);
            resolve(base64.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function blobToAudioBuffer(blob: Blob) {
    const arrayBuffer = await blob.arrayBuffer();
    // @ts-ignore
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    return audioContext.decodeAudioData(arrayBuffer);
}
