import React from 'react';

import { ChatIdMessages } from '@/components/chat-id-messages';
import { PDFAgentDropBox } from '@/components/input-controls/input-controls';
import { Chat } from '@/utils/types';
import { Flex } from '@chakra-ui/react';

export function PDFChat({ chat }: { chat?: Chat | null }) {
    return (
        <Flex maxW={1200} m={'auto'} justifyContent={'center'} alignItems={'center'} h={'100%'} gap={4}>
            <PDFAgentDropBox />
            {chat && <ChatIdMessages item={chat} />}
        </Flex>
    );
}
