import React, { useRef, useState } from 'react';
import { BiChevronDown, BiText } from 'react-icons/bi';
import { BsArrowRight, BsFillFileEarmarkTextFill, BsFilm, BsGlobe2, BsImage, BsSoundwave } from 'react-icons/bs';
import { HiOutlineSparkles } from 'react-icons/hi';

import { rekaFactory } from '@/api/reka';
import { useChatControls } from '@/contexts/chat-controls';
import { useGetMultiModalQuestions } from '@/hooks/use-get-random-questions';
import { useSendMessage } from '@/hooks/use-send-message';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Heading,
    IconButton,
    List,
    ListItem,
    SimpleGrid,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
} from '@chakra-ui/react';

const typeIcon = {
    text: (
        <Box as={'span'} color={'text-subtle'}>
            <BiText />
        </Box>
    ),
    image: (
        <Box as={'span'} color={'text-subtle'}>
            <BsImage />
        </Box>
    ),
    video: (
        <Box as={'span'} color={'text-subtle'}>
            <BsFilm />
        </Box>
    ),
    audio: (
        <Box as={'span'} color={'text-subtle'}>
            <BsSoundwave />
        </Box>
    ),
    search: (
        <Box as={'span'} color={'text-subtle'}>
            <BsGlobe2 />
        </Box>
    ),
    retrieval: (
        <Box as={'span'} color={'text-subtle'}>
            <BsFillFileEarmarkTextFill />
        </Box>
    ),
};

export function MultiModalEmptyChat() {
    const { enabledFeatures } = useAppConfig();
    const examples = useGetMultiModalQuestions();
    const { handleMessageSend } = useSendMessage();
    const { requestOption, setChatMode } = useChatControls();
    const { isLoggedOutChat, showSignupGate } = useLoggedOutChat();
    return (
        <Flex alignItems={'center'} justifyContent={'center'} minHeight={'100%'}>
            <Box>
                {!isLoggedOutChat && !enabledFeatures.rekaModes && <ModelSelector />}
                {!isLoggedOutChat && enabledFeatures.rekaModes && <ModeSelector />}
                {isLoggedOutChat && (
                    <Box w={'320px'}>
                        <Flex gap={'12px'} alignItems={'baseline'}>
                            <Heading mb={3} as={'h1'}>
                                Reka Flash
                            </Heading>
                            <IconButton
                                aria-label={'more models'}
                                onClick={() => showSignupGate('models')}
                                size="xs"
                                isRound
                            >
                                <BiChevronDown />
                            </IconButton>
                        </Flex>
                        <Text mb={8}>
                            Our turbo-class highly capable model optimized for common everyday questions.
                        </Text>
                    </Box>
                )}
                {!enabledFeatures.rekaModes && (
                    <List>
                        {examples.map((example, index) => (
                            <ListItem key={index} mb={'12px'}>
                                <Button
                                    onClick={() =>
                                        handleMessageSend(example.prompt, null, {
                                            ...requestOption,
                                            ...example.params,
                                        })
                                    }
                                    variant={'link'}
                                    leftIcon={typeIcon[example.type]}
                                    rightIcon={<BsArrowRight />}
                                >
                                    {example.label}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </Flex>
    );
}

function ModeSelector() {
    const { setChatMode } = useChatControls();
    return (
        <Box>
            <Heading mb={3} size={'md'} as={'h2'}>
                Our Agents
            </Heading>
            <SimpleGrid columns={3} spacing={3}>
                <Box
                    onClick={() => setChatMode('perception')}
                    flex={1}
                    rounded={6}
                    bg={'background-secondary'}
                    p={4}
                    as={'button'}
                >
                    <Flex gap={3}>
                        <Stack textAlign={'left'}>
                            <Text fontSize={'lg'} fontWeight={3}>
                                Perception Agent
                            </Text>
                            <Text fontSize={'xs'}>Extract structured details from your videos or images</Text>
                        </Stack>
                    </Flex>
                </Box>
                <Box
                    onClick={() => setChatMode('pdf')}
                    flex={1}
                    rounded={6}
                    bg={'background-secondary'}
                    p={4}
                    as={'button'}
                >
                    <Flex gap={3}>
                        <Stack textAlign={'left'}>
                            <Text fontSize={'lg'} fontWeight={3}>
                                Analyst Agent
                            </Text>
                            <Text fontSize={'xs'}>
                                Discuss content from your PDF
                                <br />{' '}
                            </Text>
                        </Stack>
                    </Flex>
                </Box>
                <Box
                    onClick={() => setChatMode('speech2speech')}
                    flex={1}
                    rounded={6}
                    bg={'background-secondary'}
                    p={4}
                    as={'button'}
                >
                    <Flex gap={3}>
                        <Stack textAlign={'left'}>
                            <Text fontSize={'lg'} fontWeight={3}>
                                Speech to Speech Agent
                            </Text>
                            <Text fontSize={'xs'}>Speech based conversation agent</Text>
                        </Stack>
                    </Flex>
                </Box>
            </SimpleGrid>
        </Box>
    );
}

function ModelSelector() {
    const {
        setOption,
        requestOption,
        uploadControls: { mediaType },
    } = useChatControls();
    const { enabledFeatures } = useAppConfig();
    const modelName = requestOption.modelName;
    const isFlash =
        !modelName ||
        modelName.includes('flash') ||
        // (!enabledFeatures.rekaCore && modelName.includes('default')) ||
        modelName.includes('default') || // default to flash for new flash release
        mediaType === 'audio';
    const isCore =
        mediaType !== 'audio' &&
        // Don't default to core for flash release
        // (modelName.includes('core') || (enabledFeatures.rekaCore && modelName.includes('default')));
        modelName.includes('core');
    const isEdge = modelName.includes('edge') && mediaType !== 'audio';

    return (
        <Box maxW={'100%'} width={'360px'} margin={'auto'}>
            <ButtonGroup width={'100%'} mb={'12px'} size="lg" isAttached variant="purpleOutline">
                {enabledFeatures.rekaCore && (
                    <Button
                        onClick={() => {
                            setOption({ modelName: 'reka-core' });
                        }}
                        isActive={isCore}
                    >
                        Core
                    </Button>
                )}
                <Button
                    leftIcon={<HiOutlineSparkles />}
                    onClick={() => {
                        setOption({ modelName: 'reka-flash' });
                    }}
                    isActive={isFlash}
                >
                    Flash
                </Button>
                <Button
                    onClick={() => {
                        setOption({ modelName: 'reka-edge' });
                    }}
                    isActive={isEdge}
                >
                    Edge
                </Button>
                <Button isDisabled={true}>Spark</Button>
            </ButtonGroup>
            <Box height={'70px'}>
                {isCore && <Text fontSize={'12px'}>Our largest and most capable model able to handle any task.</Text>}
                {isFlash && (
                    <Text fontSize={'12px'}>
                        Our turbo-class highly capable model optimized for common everyday questions.{' '}
                        <Tag ml={2} size={'sm'} variant="subtle" colorScheme="gray">
                            <TagLeftIcon boxSize="12px" as={HiOutlineSparkles} />
                            <TagLabel>Updated</TagLabel>
                        </Tag>
                    </Text>
                )}
                {isEdge && (
                    <Text fontSize={'12px'}>
                        Our smallest model, ideal for cases where speed is paramount. Also suitable for local or
                        on-device usages.
                    </Text>
                )}
            </Box>
        </Box>
    );
}
