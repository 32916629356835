import { useRouter } from 'next/router';

import { Bff } from '@/api/bff';
import { ChatMetadata } from '@/api/firestore';
import { IChatParams, rekaFactory } from '@/api/reka';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChats } from '@/store/hooks/use-chat-store';
import { useErrorMessageStore } from '@/store/hooks/use-error-message-store';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { compact, getChatHistory, sliceContext } from '@/utils/helper';
import { getHeartbeat, stopRequests } from '@/utils/stop-coordinator';
import { Message } from '@/utils/types';
import { useUser } from '@auth0/nextjs-auth0/client';
import { captureMessage } from '@sentry/nextjs';

import { isProd } from '../../environments';

export function useSendMessage () {
    const { setErrorMessageAction } = useErrorMessageStore();
    const { apiKey } = useAuthStore();
    const { appUser } = useAuthStore();
    const { user } = useUser();
    const router = useRouter();
    const { chatParameters, allPersonas, enabledFeatures } = useAppConfig();
    const {
        isLoggedOutChat,
        setLoggedOutChat,
        updateLoggedOutChatResponse,
        updateLoggedOutChat,
        addLoggedOutChatMessage,
        chat: loggedOutChat,
    } = useLoggedOutChat();
    const {
        chats,
        clearChatContext,
        createChat,
        updateChat,
        addChatMessage,
        updateChatMessage,
        saveChat,
        getState,
        updateChatLoading,
    } = useChats();
    const UID = appUser?.uid || ''; // UID should be present

    const stopGeneration = (chatId: string) => {
        stopRequests(chatId);
        if (isLoggedOutChat) {
            updateLoggedOutChat({ loading: false });
            return;
        }
        updateChatLoading(chatId, false);
        const history = getChatHistory(chats, chatId).history!;
        ChatMetadata.registerEvent(
            UID,
            chatId,
            { name: 'stop_generation', messageIndex: history.length - 1, lastResponse: history?.at(-1)?.text ?? '' },
            history, // everything included the stopped turn
        );
    };

    const handleCreateTitle = async (chatId: string) => {
        const chatHistory = getChatHistory(getState(), chatId)?.history;
        if (!chatHistory || chatHistory.length !== 2) return;
        const bff = new Bff(apiKey);
        const { data } = await bff.generateTitle(chatHistory);
        if (data && data.title) updateChat(chatId, { title: data.title });
    };
    const handleMessageSend = async (
        text: string,
        chatId: string | null = null,
        params: Omit<IChatParams, 'human' | 'persona'> & { persona?: string } = {},
        clearContext: boolean = false,
    ) => {
        if (text.trim() === '') return;
        let conversationHistory: Message[] = [];
        let removeMedia = params.useSearchEngine ?? false;
        if (!enabledFeatures.audioUpload && params.mediaType === 'audio') return;
        if (!enabledFeatures.videoUpload && params.mediaType === 'video') return;
        if (!enabledFeatures.longContext && params.mediaType === 'application') return;
        if (!enabledFeatures.rekaCore && isProd && params.modelName?.includes('core')) return;
        if (params.mediaType === 'audio') {
            params.modelName = 'reka-flash';
        }
        if (params.modelName === 'default') {
            // if (enabledFeatures.rekaCore) params.modelName = 'reka-core';
            // else params.modelName = 'reka-flash';
            params.modelName = 'reka-flash'; // default to new flash for flash release
        }
        if (params.modelName === 'reka-flash' && enabledFeatures.flashDefaultSearch && !params.mediaUrl) {
            params.useSearchEngine = true;
        }
        if (params.mediaType === 'application') {
            params.mediaType = 'pdf';
        }
        if (params.mediaType === 'custom') {
            // we need a way figure out what media type
            params.mediaType = 'video';
        }

        if (isLoggedOutChat) {
            const bff = new Bff(null);
            conversationHistory = loggedOutChat?.history || [];
            const inMemoryId = Math.random().toString();
            if (params.mediaUrl || !loggedOutChat) {
                conversationHistory = [];
                setLoggedOutChat({
                    id: inMemoryId,
                    title: text,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    history: [
                        compact({
                            type: 'human' as const,
                            text,
                            image_url: params.mediaUrl,
                            media_type: params.mediaType,
                        }),
                    ],
                    modelName: 'reka-flash',
                    loading: true,
                });
            } else {
                addLoggedOutChatMessage({
                    type: 'human',
                    text,
                });
                updateLoggedOutChat({ loading: true });
            }
            const iterator = await bff.loggedOutChatStream({
                human: text,
                conversationHistory,
                modelName: 'reka-flash',
                mediaUrl: params.mediaUrl,
                mediaType: params.mediaType,
            });
            let message: Message;
            const messageIndex = conversationHistory.length + 1;
            updateLoggedOutChatResponse({ messageIndex, message: { type: 'model', text: '' } });
            const ping = getHeartbeat(inMemoryId);
            for await (message of iterator) {
                if (!ping()) {
                    break;
                }
                updateLoggedOutChatResponse({ messageIndex, message });
            }
            updateLoggedOutChat({ loading: false });
            return;
        }

        if (chatId === null) {
            const chat = {
                title: params.title ?? text,
                history: [
                    compact({
                        type: 'human' as const,
                        text,
                        use_search_engine: params.useSearchEngine || true,
                        use_code_interpreter: params.useCodeInterpreter || undefined,
                        image_url: params.mediaUrl,
                        media_type: params.mediaType,
                        file_url: params.fileUrl,
                    }),
                ],
                retrievalDataset: params.retrievalDataset,
                persona: params.persona,
                modelName: params.modelName,
                mode: params.mode,
            };
            chatId = createChat(chat);
            ChatMetadata.addMetadata(UID, chatId, {
                userAgent: navigator?.userAgent ?? '',
                domain: user?.email?.split('@')?.at(-1) ?? '',
                createdAt: new Date(),
                modelName: params.modelName || 'reka-flash',
            });
            router.replace(`/chat/${chatId}`);
        } else {
            conversationHistory = getChatHistory(chats, chatId).history!;

            updateChat(chatId, { persona: params.persona, modelName: params.modelName });

            if ((params.mediaUrl && !enabledFeatures.interleaveMedia) || clearContext) {
                clearChatContext(chatId);
            }

            addChatMessage(chatId, {
                type: 'human',
                text,
                use_search_engine: params.useSearchEngine || undefined,
                use_code_interpreter: params.useCodeInterpreter || undefined,
                // image_url: params.mediaUrl,
                media_type: params.mediaType,
                file_url: params.fileUrl,
            });
        }

        setErrorMessageAction({ currentId: chatId, message: '' });

        const responseIndex = conversationHistory.length + 1;
        if ((params.mediaUrl && !enabledFeatures.interleaveMedia) || clearContext) {
            conversationHistory = [];
        }
        if (!params.mediaUrl && !params.fileUrl && removeMedia) {
            conversationHistory = conversationHistory.map((message) => {
                return {
                    ...message,
                    media_type: undefined,
                    file_url: undefined,
                    image_url: undefined,
                };
            });
        }
        const ping = getHeartbeat(chatId);
        try {
            const reka = await rekaFactory(apiKey);
            updateChatLoading(chatId, true);
            const iterator = reka.chatStream({
                ...chatParameters,
                ...params,
                human: text,
                conversationHistory: sliceContext(conversationHistory),
                persona: params?.persona
                    ? allPersonas.find((entry) => entry.name === params.persona)?.persona
                    : undefined,
                modelName: params.modelName || chatParameters.modelName || 'reka-flash',
            });
            updateChatMessage(chatId, responseIndex, { type: 'model', text: '' });

            let response: Message;
            for await (response of iterator) {
                if (!ping()) {
                    reka.stop();
                    break;
                }
                updateChatMessage(chatId, responseIndex, response);
            }
        } catch (error) {
            captureMessage('chat api error', {
                user: {
                    username: user?.sub!,
                },
                extra: {
                    error,
                    model: params.modelName,
                    prompt: text,
                },
                level: 'error',
            });
            console.log('SSE error', { error });
            let errorMsg = 'Network error';
            if (error instanceof Error) {
                errorMsg = error.message;
            } else if (typeof error === 'string') {
                errorMsg = error;
            }
            setErrorMessageAction({
                currentId: chatId,
                message: errorMsg,
            });
        }
        ping(true);
        updateChatLoading(chatId, false);
        saveChat(chatId);
        // await handleCreateTitle(chatId);
    };

    const handleMessageSendStateLess = async (
        text: string,
        chatId: string | null = null,
        params: Omit<IChatParams, 'human' | 'persona'> & { persona?: string } = {},
        onMessage: (message: string) => boolean,
    ) => {
        if (text.trim() === '') return;
        let conversationHistory: Message[] = [];
        if (params.mediaType === 'audio') {
            params.modelName = 'reka-flash';
        }
        if (params.modelName === 'default') {
            params.modelName = 'reka-flash'; // default to new flash for flash release
        }
        if (params.mediaType === 'application') {
            params.mediaType = 'pdf';
        }

        const chat = {
            title: params.title ?? text,
            history: [
                compact({
                    type: 'human' as const,
                    text,
                    use_search_engine: params.useSearchEngine || true,
                    use_code_interpreter: params.useCodeInterpreter || undefined,
                    image_url: params.mediaUrl,
                    media_type: params.mediaType,
                    file_url: params.fileUrl,
                }),
            ],
            retrievalDataset: params.retrievalDataset,
            persona: params.persona,
            modelName: params.modelName,
            mode: params.mode,
        };

        const reka = await rekaFactory(apiKey);
        const iterator = reka.chatStream({
            ...chatParameters,
            ...params,
            human: text,
            conversationHistory: sliceContext(conversationHistory),
            persona: params?.persona
                ? allPersonas.find((entry) => entry.name === params.persona)?.persona
                : undefined,
            modelName: params.modelName || chatParameters.modelName || 'reka-flash',
        });

        let response: Message;
        for await (response of iterator) {
            if (!onMessage(response.text)) return
        }
    };

    return {
        handleCreateTitle,
        handleMessageSend,
        stopGeneration,
        handleMessageSendStateLess,
    };
}
