import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useUserAuth } from '@/contexts/user-authentication';
import { useSendMessage } from '@/hooks/use-send-message';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';

const EXAMPLES: Record<string, { prompt: string; params: { modelName: string } }> = {
    '1': {
        prompt: 'Imagine a futuristic city on Mars.',
        params: {
            modelName: 'reka-flash',
        },
    },
    '2': {
        prompt: 'Describe a world where AI rules.',
        params: {
            modelName: 'reka-flash',
        },
    },
    '3': {
        prompt: 'What if humans had superpowers?',
        params: {
            modelName: 'reka-flash',
        },
    },
    '4': {
        prompt: 'Envision a utopia under the sea.',
        params: {
            modelName: 'reka-flash',
        },
    },
    '5': {
        prompt: 'Imagine life on a distant exoplanet.',
        params: {
            modelName: 'reka-flash',
        },
    },
    '6': {
        prompt: 'How would a cyberpunk metropolis look?',
        params: {
            modelName: 'reka-flash',
        },
    },
    '7': {
        prompt: 'Create a packing list for an adult male going to Maine in the summer for 5 days.',
        params: {
            modelName: 'reka-flash',
        },
    },
    '8': {
        prompt: 'I need to redo the roof of my 2000 square feet house. I want something mid range, how much would it cost me?',
        params: {
            modelName: 'reka-flash',
        },
    },
    '9': {
        prompt: 'My friend recently experienced a loss in her family and within the same week got let go from her job. What are some things I can say to comfort her?',
        params: {
            modelName: 'reka-flash',
        },
    },
    '10': {
        prompt: `Can you help me reduce this recipe by half and find an alternative for cumin
1 ½ cups cooked chickpeas
1/4 cup fresh lemon juice
1/4 cup tahini
1 small garlic clove, minced
2 tablespoons extra-virgin olive oil
1/2 teaspoon ground cumin
2 to 3 tablespoons cold water
`,
        params: {
            modelName: 'reka-flash',
        },
    },
};

export function useQueryExample() {
    const router = useRouter();
    const { handleMessageSend } = useSendMessage();
    const { query } = router;
    const { isReady } = useUserAuth();
    const { isLoggedOutChat } = useLoggedOutChat();
    useEffect(() => {
        if (!query?.example) return;
        if (!isLoggedOutChat && !isReady) return;
        const exampleId = query.example as string;
        const example = EXAMPLES[exampleId];

        if (exampleId && example) {
            router.replace(
                {
                    pathname: router.pathname,
                    query: '',
                },
                undefined,
                { shallow: true },
            );
            handleMessageSend(example.prompt, null, example.params);
        }
    }, [query, isReady, isLoggedOutChat]);
}
